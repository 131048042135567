import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
import Image1 from '../images/etc/happy-haus-border-6.jpg'
const ogImages = [`https://happyhaus.com.au${Image1}`]

const LandscapeImage =
  'etc/happyhaus-gallery-2024-02-22-photo-flower-1110871.jpg'

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Privacy"
          description="What are Happy Haus’s privacy obligations? In dealing with personal information, Happy Haus is subject to the Privacy Act 1988 (Cth) (Privacy Act), the
                    Australian Privacy Principles and any applicable State or Territory privacy laws."
          images={ogImages}
        />

        <Row>
          <Flex>
            <Box width={1} px={2}>
              <Image src={LandscapeImage} ratio={1 / 1.5} />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex>
            <Box width={1} px={2}>
              <Title align="left">Privacy Policy</Title>
              <Copy>
                <p>
                  <p>What are Happy Haus’s privacy obligations?</p>
                  <p>
                    Happy Haus Pty Ltd ABN 64 136 082 909 (Happy Haus, we, our,
                    us) privacy obligations concern the collection, use,
                    disclosure and storage of personal information.
                  </p>
                  <p>
                    In dealing with personal information, Happy Haus is subject
                    to the Privacy Act 1988 (Cth) (Privacy Act), the Australian
                    Privacy Principles and any applicable State or Territory
                    privacy laws.
                  </p>
                  <p>Happy Haus's Personal Information Handling Practices</p>
                  <p>What is personal information?</p>
                  <p>
                    "Personal information" means information or an opinion
                    (whether true or not, and whether it is recorded in a
                    material form or not) about an identified individual, or an
                    individual who is reasonably identifiable.
                  </p>
                  <p>
                    What personal information does Happy Haus collect and hold?
                  </p>
                  <p>
                    The types of information we collect and hold includes (but
                    is not limited to) personal information about customers,
                    prospective employees and contractors, which may include,
                    for example, an individual's name, title, email address,
                    address, organisation, financial information (such as a
                    customer's bank statements) and phone number.
                  </p>
                  <p>
                    We collect and hold personal information from individuals
                    who communicate with us directly via telephone, email, in
                    person, or through our website (including prospective
                    employees and individuals who are contacting us on behalf of
                    a business that wishes to engage our services or enquire
                    about our products). We may also collect your personal
                    information from third parties for the purpose of conducting
                    our business. These third parties may include but are not
                    limited to land agents, finance brokers and third party
                    referrers. We will only collect your personal information
                    from third parties with your consent, unless it is
                    unreasonable or impracticable to do so.
                  </p>
                  <p>
                    You can always choose to deal with us anonymously (or by
                    providing a pseudonym), unless it is impracticable for us to
                    deal with you on that basis.
                  </p>
                  <p>
                    We usually collect information directly from you when you
                    visit a display centre, make a phone call, send us an email
                    or submit personal information to us via our website or
                    through a written application. If you choose not to provide
                    your personal information to Happy Haus this may affect your
                    ability to enquire about or engage our services, enquire
                    about prospective employment with us or utilise the
                    functionalities of our website.
                  </p>
                  <p>
                    For what purposes does Happy Haus collect, hold, use and
                    disclose your personal information?
                  </p>
                  <p>
                    We collect, hold, use and disclose your personal information
                    for the purpose of providing our products and services,
                    conducting our business, communicating with you, promoting
                    our business and complying with our legal obligations.
                  </p>
                  <p>
                    Some examples of the specific purposes for which we may
                    collect, hold, use and disclose your personal information
                    include the following:
                  </p>
                  <p>
                    <ul>
                      <li>
                        responding to a business query regarding our products or
                        services;
                      </li>
                      <li>
                        managing, operating and improving our website and
                        services;
                      </li>
                      <li>
                        dealing with enquiries regarding prospective employment
                        with us;
                      </li>
                      <li>
                        entering into a business transaction, including but not
                        limited to providing quotes and orders; and,
                      </li>
                      <li>
                        promoting our products, services and offers to you
                        through a range of mediums, including but not limited to
                        email, social media and third party online advertising
                        on websites such as Facebook and Google.
                      </li>
                    </ul>
                  </p>
                  <p>Overseas disclosures</p>
                  <p>
                    We may disclose your personal information to recipients
                    which are located outside of Australia, including the United
                    States of America. We will only disclose your personal
                    information to overseas recipients if you consent to the
                    disclosure, if we have taken steps which are reasonable in
                    the circumstances to ensure that the overseas recipient does
                    not breach the Australian Privacy Principles as required
                    under the Privacy Act, or if the disclosure is permitted
                    under the Privacy Act.
                  </p>

                  <p>
                    Happy Haus stores the personal information we hold on an
                    internal secure computerised database which is located in
                    Australia.
                  </p>
                  <p>
                    How can you elect to stop receiving marketing communications
                    from us?
                  </p>
                  <p>
                    We may use your personal information to send you marketing
                    and promotional information we will give you the option to
                    opt out from that specific communication, including by using
                    a link to unsubscribe from future communications.
                  </p>
                  <p>
                    When we use your personal information for targeted
                    advertising on third party websites like Facebook, we will
                    take reasonable steps to ensure that the third party
                    provider gives you the option to opt out from targeted
                    online advertising from Happy Haus. If you wish to opt out
                    of receiving sponsored advertisements from Happy Haus on
                    Facebook, you can do so by firstly clicking the cancel
                    symbol (X) on the top right corner of our advertisements on
                    Facebook, and secondly, by contacting us directly to let us
                    know. As Facebook controls the display of your ad
                    preferences, please refer to Facebook's page titled About
                    Facebook Adverts for an explanation on how to adjust your
                    Facebook ad preferences, available at Facebook.
                  </p>
                  <p>
                    If you do not wish to receive direct marketing from us or
                    from third parties engaged by us, please let us know by
                    contacting us on 07 3186 5771 or at
                    homes@happyhaus.com.au By electing not to opt-out, we assume
                    that you have given consent to receive similar marketing
                    communications and advertising from us in the future.
                  </p>
                  <p>How does Happy Haus use cookies?</p>
                  <p>
                    A cookie is a small text file stored by the web browser
                    software on your computer when you access our website. You
                    can choose to disable cookies through your web browser
                    settings, but if you do this you may not be able to use the
                    full functionality of our website.
                  </p>
                  <p>
                    We use cookies to maintain our website and enhance your
                    experience when using our website. We also use cookies as a
                    method of targeting our advertising to you if you have
                    previously visited our website via remarketing services
                    including but not limited to, Google AdWords. Third party
                    vendors, including Google, may use cookies to show our
                    advertisements to you when you visit websites owned by third
                    parties. You can opt out of Google's use of cookies by
                    adjusting your Google Ad Settings at
                    http://www.google.com/settings/ads/onweb/, or alternatively,
                    by visiting the Network Advertising Initiative opt out page,
                    which is available at
                    http://www.networkadvertising.org/choices/.
                  </p>
                  <p>
                    We will not attempt to identify anonymous users or your
                    browsing activities except in the unlikely event of an
                    investigation, where a law enforcement agency may exercise a
                    warrant to inspect the Internet Service Provider's log
                    files.
                  </p>
                  <p>
                    Our website may contain links to other websites that are not
                    controlled or owned by us. We are not responsible for these
                    websites, the privacy policies or practices of those
                    websites, or for any consequences arising from your use of
                    those websites. We recommend that you read the privacy
                    policies made available on those websites to understand
                    their privacy practices.
                  </p>
                  <p>External websites and webpages</p>
                  <p>
                    Our website and this Privacy Policy may contain links to
                    other websites that are not controlled or owned by us. We
                    are not responsible for these websites, their content or
                    links, the privacy policies or practices of those websites,
                    or for any consequences arising from your use of those
                    websites. We recommend that you read the privacy policies
                    and any other relevant information made available on those
                    websites to understand their privacy practices.
                  </p>
                  <p>How does Happy Haus protect your personal information?</p>
                  <p>
                    Happy Haus takes reasonable steps to protect personal
                    information that is held by us from misuse, interference and
                    loss, and unauthorised access, modification or
                    disclosure. We will permanently destroy or de-identify
                    personal information we hold about you if we no longer need
                    it for any purpose.
                  </p>
                  <p>Queries, Concerns & Further Information</p>
                  <p>
                    How can I access the information held about me and seek
                    correction?
                  </p>
                  <p>
                    We have measures in place to ensure that the information we
                    hold about you is accurate, complete and up-to-date before
                    acting on it. If you learn that personal information we hold
                    about you is inaccurate, incomplete or not up-to-date you
                    should contact us so that your information can be updated.
                  </p>
                  <p>
                    If you wish to see what information we hold about you, you
                    can ask for a copy of it.
                  </p>
                  <p>
                    We will not charge you for lodging a request for a copy of
                    your personal information but you may be asked to pay a
                    reasonable fee for the work involved in providing you with
                    this information and for associated costs such as
                    photocopying. You will be notified of any likely costs
                    before your request is processed.
                  </p>
                  <p>What if I have a complaint?</p>
                  <p>
                    If you have a privacy complaint or concern, especially if
                    you think your privacy has been affected or you wish to
                    complain about a refusal to update or grant access to our
                    records of your personal information, you should contact us
                    as detailed below for an examination of your complaint. If
                    after that you are still unhappy you can complain about a
                    privacy matter to the Australian Information Commissioner.
                    See www.oaic.gov.au for how to make a complaint.
                  </p>
                  <p>
                    We may update this Privacy Policy from time to time. To see
                    our most current Privacy Policy, please visit our website at
                    www.happyhaus.com.au or contact us.
                  </p>
                  <p>How can you contact us?</p>
                  <p>
                    Email: homes@happyhaus.com.au Phone: 07 3186 5771 Postal
                    address: 1108 Nudgee Road, Hamilton, QLD, 4007.
                  </p>
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default Page
